import { Controller } from "stimulus"

export default class extends Controller {
  static targets = []

  toggle_group_type() {

    const element = event.target
    const feature_id = element.dataset.feature
    const group_type_id = element.dataset.id

    $.ajax({
      method: 'PATCH',
      url: `/features/${feature_id}/toggle_group`,

      data: {
        group_type_id: group_type_id
      },

      success: (data) => {

      },

      error: () => {
        // TODO - remonter le message
        alert('Erreur inconnu 005, veuillez contacter le support.')
      },

      dataType: 'script'
    });

  }
}