// Visit The Stimulus Handbook for more details 
// https://stimulusjs.org/handbook/introduction
// 
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "q" ]

  connect() {
  }

  launch() {
    console.log('Launch ....')

    const q = this.qTarget.value
    const url = this.data.get("url")
    let searchData = null

    console.log("Searching " + q + " on " + url )

    if(q.length > 0) {
      searchData = $("#search").serialize()
    }

    $.get({
      url: url,
      data: searchData,
      success: null,
      dataType: 'script'
    });

    return true
  }

}
